import "babel-polyfill"

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import moment from "moment"

Vue.config.productionTip = false

moment.locale('it');

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')

Vue.filter("formatDate", function (value) {
  if (value) {
      return moment(String(value)).format("DD/MM/YYYY")
  }
})

Vue.filter("formatTime", function (value) {
  if (value) {
      return moment(String(value)).format("HH:mm")
  }
})

Vue.filter("formatCurrency", function (value) {
  if (value) {
    return value.toFixed(2) + " €"
  }
})