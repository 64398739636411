import axios from "axios"

export default {
    loadLocalStorage(callback){
        if (localStorage.getItem("ApiUrl") == null) {
            axios.get("static/appsettings.json").then(response => {
                localStorage.setItem("ApiUrl", response.data.ApiUrl)
                localStorage.setItem("MecurioApiUrl", response.data.MecurioApiUrl)
                if (typeof (callback) == "function") { callback(); }
            });
        } else {
            if (typeof (callback) == "function") { callback(); }
        }
    },

    getBusStopInfo(stopcode, cb){
        this.loadLocalStorage(function (response){
            axios.get(localStorage.getItem("ApiUrl") + "/polemonitor/info?StopCode=" + stopcode).then(
                response => {
                    cb(response["data"])
                })   
        } );
    },

    getGroupInfo(groupcode, cb) {
            axios.get(localStorage.getItem("ApiUrl") + "/polemonitor/GroupInfo?GroupCode=" + groupcode).then(
                response => {
                    cb(response["data"])
                })
    },

    getRuns(stopcode, isUrban, cb, errorCb){
        this.loadLocalStorage(function (response){
            axios.get(localStorage.getItem("ApiUrl") + "/polemonitor/mrcruns?StopCode=" + stopcode + "&IsUrban=" + isUrban)
            .then(response => {
                cb(response["data"])
            })
            .catch(error => {
                errorCb(error.response)
            });  
        } );
    },

    getGroupRuns(groupcode, isUrban, cb, errorCb) {
        axios.get(localStorage.getItem("ApiUrl") + "/polemonitor/mrcgroupruns?GroupCode=" + groupcode + "&IsUrban=" + isUrban)
            .then(response => {
                cb(response["data"])
            })
            .catch(error => {
                errorCb(error.response)
            });
    },


    getLineTrack(lineCode, direction, race, spare2, cb) {
        axios.get(localStorage.getItem("ApiUrl") + "/polemonitor/LineGeoTrack?Line=" + lineCode + "&Race=" + race ).then(
            response => {
                cb(response["data"])
            })
    }


}