<template>
  <v-footer color="white" fixed padless>
    <template>
      <v-bottom-navigation>
        <v-btn
          value="recent"
          :href="'https://www.mycicero.it/tplfvg-go/TPWebPortal/?crosspage=true&sDesc='+pole.Address+'&sLat='+ pole.Latitude+'&sLng='+ pole.Longitude+'&sEpsg=4326'"
          target="_blank"
        >
          <span>Acquisto Biglietti</span>

          <v-icon>mdi-ticket-confirmation-outline</v-icon>
        </v-btn>

        <v-btn
          value="nearby"
          href="https://tplfvg.it/it/le-tariffe/acquisto-web/"
          target="_blank"
        >
          <span>Acquisto Abbonamenti</span>

          <v-icon>mdi-ticket-account</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>
  </v-footer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "tplfooter",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      pole: (state) => state.busStopInfo,
    }),
  },
};
</script>

<style>
</style>