<template>
    <div>
        <v-btn class="back"
            fixed
            absolute
            dark
            fab
            top
            right
            color="tplprimary"
            :style="btntop"
            @click="back()"
        >
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true"
                data-projection="EPSG:4326" :style="height" ref="map">
            <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation" ref="mapView"></vl-view>
            <vl-layer-tile id="osm">
                <vl-source-osm></vl-source-osm>
            </vl-layer-tile>
            
            <vl-layer-vector ref="all" :z-index="2">
                <vl-source-vector ref="vectorSource" >
                    <vl-feature id="marker" ref="marker" :z-index="3">
                        <template>
                            <vl-geom-point :coordinates="polePosition"></vl-geom-point>
                            <vl-style-box>
                                <vl-style-icon src="static/marker.png" :scale="0.5" :anchor="[0.5, 1]" :size="[48, 48]"></vl-style-icon>
                            </vl-style-box>
                        </template>
                    </vl-feature>
                    
                    <vl-feature id="busmarker" ref="busmarker" :z-index="2">
                        <template v-if="getBusCoordinates().Longitude != 0">
                            <vl-geom-point :coordinates="getBusCoordinates()"></vl-geom-point>
                            <vl-style-box>
                                <vl-style-icon src="static/bus-marker-alt.png" :scale="0.7" :anchor="[0.5, 1.2]" :size="[48, 48]"></vl-style-icon>
                                <vl-style-text :text="getBusCode()" font="12px sans-serif">
                                    <vl-style-fill color="black"/>
                                    <vl-style-stroke color="white" :width="3" />
                                </vl-style-text>
                            </vl-style-box>
                        </template>
                    </vl-feature>
                    
                    <vl-feature :z-index="1">
                        <vl-geom-multi-line-string :coordinates="getlineCoods()"></vl-geom-multi-line-string>
                        <vl-style-box>
                            <vl-style-stroke color="#007D8A" :width="3"></vl-style-stroke>
                        </vl-style-box>
                    </vl-feature>    
                </vl-source-vector>
                

            </vl-layer-vector>

            <vl-source-vector ref="vectorSourceForZoom" >
                    <vl-feature>
                        <template>
                            <vl-geom-point :coordinates="polePosition"></vl-geom-point>
                        </template>
                    </vl-feature>
                    
                    <vl-feature>
                        <template v-if="getBusCoordinates().Longitude != 0">
                            <vl-geom-point :coordinates="getBusCoordinates()"></vl-geom-point>
                        </template>
                    </vl-feature>
                    
                </vl-source-vector>
        </vl-map>
    </div>
</template>

<script>
import Vue from 'vue'
import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css'
import { mapState, mapActions } from 'vuex'

Vue.use(VueLayers)

export default {
    name: 'routemap',
    data() {
        return{
            zoom: 15,
            center: [0, 0],
            rotation: 0,
            geolocPosition: undefined,
            height: "height:" + (window.innerHeight - 100) + "px",
            btntop: "top:" +(window.innerHeight - 80)+ "px", 
        }
    },
    computed: {
        ...mapState({
            runs: state => state.runs,
            line: state => state.line,
            lineCoods: state => state.lineCoords,
            busStopInfo: state => state.busStopInfo
        }),
        polePosition: function(){
             return  this.line.PoleCoords
        }
    },
    created() {
        this.center = this.polePosition
    },
    methods:{
        getBusCoordinates(){
            var run = this.runs.find(r => r.Line == this.line.Number && r.Race == this.line.Race) 
            if (run == undefined) {
                run = {Longitude: 0, Latitude: 0}
            } 
            return [run.Longitude, run.Latitude]     
        },
        getBusCode(){
            var run = this.runs.find(r => r.Line == this.line.Number && r.Race == this.line.Race) 
            if (run == undefined) {
                return ""         
            } else {
                return run.Vehicle;
            }
        },
        back(){
            var self= this
            if(this.busStopInfo.IsStation){
                setTimeout(function(){
                    self.$store.commit("setStationTab", "tab-arrivals")
                    self.$store.commit("setCurrentComponent", "station")}, 100)
            } else {
                if(this.busStopInfo.Stops == undefined){
                    this.$store.commit("setCurrentComponent", "home")
                } else {
                    this.$store.commit("setCurrentComponent", "group")
                }
            }
        },
        onResize() {
            this.height = "height:" + (window.innerHeight - 100) + "px"
            this.btntop = "top:" +(window.innerHeight - 80)+ "px"
        },
        getlineCoods(){
          return this.lineCoods
        }
    },
    mounted () {
        setTimeout(() => {    
                this.$refs.mapView.$view.fit(this.$refs.vectorSourceForZoom.$source.getExtent(), {
                size: this.$refs.map.$map.getSize(),
                duration: 1000,
            })
        }, 1000),
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            window.addEventListener('orientationchange', this.onResize);
        })
    }, 
    watch: {
        zoom : function(val){
           //console.log(val)     
        }
    }
   
}
</script>
<style scoped>
    .back{
        margin: 0 0 43px 0;
    }
</style>