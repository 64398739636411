import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.red.darken1, // #E53935
                secondary: colors.red.lighten4, // #FFCDD2
                accent: colors.indigo.base, // #3F51B5
                tplprimary: "#007D8A", //Verde petrolio
                tplsecondary: "#575756", // Grigio scuro 
                tplurban: "#ED9B33", // Arancio 
                tplextraurban: "#00AA13", // verde
                tplmaritime: "#00358E" // blu
            },
        },
        options: {
            customProperties: true,
        },
    },
});
