import Vue from 'vue'
import Vuex from 'vuex'
import server from "../api/server"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        busStopInfo:{},
        loading: true,
        loading2: false,
        loadingTrack: false,
        runs:[],
        line: null,
        stopCode: null,
        groupCode: null,
        lineCoords: [],
        typeChoose: null,
        currentComponent: null,
        errorHandeler: null,
        showDepartures: true,
        stationTab: "tab-departures"
    },
    getters:{
        busStopInfo: state => state.busStopInfo,
        runs: state => state.runs
    },
    mutations: {
        setBusStopInfo(state, busStopInfo) {
            state.busStopInfo = busStopInfo
        },
        setBusStopRunsInfo(state, busStopRunInfo) {
            state.errorHandeler = null
            state.runs = busStopRunInfo
            state.loading = false
            console.log(state.runs)
        },
        setBusStopRunsError(state, error){
            state.errorHandeler = error    
            state.loading = false
        },
        setLine(state, line){
            state.line = line
        },
        setStopCode(state, stopCode){
            state.stopCode = stopCode
        },
        setGroupCode(state, groupCode) {
            state.groupCode = groupCode
        },
        setLineGeoTrack(state, lineCoords){
            state.lineCoords = lineCoords
            state.loadingTrack = false
        },
        setTypeChoose(state, choose){
            state.typeChoose = choose
        },
        setCurrentComponent(state, component) {
            state.currentComponent = component
        },
        setDeparturesArrivals(state, value){
            state.showDepartures = value
        },
        setStationTab(state, value){
            state.stationTab = value
        }
    },
    actions: {
        getBusStopInfo(context, busStopId){
            this.state.loading = true
            server.getBusStopInfo(busStopId, busStopInfo => {
                context.commit('setBusStopInfo', busStopInfo)
            })
        },

        getGroupInfo(context, groupId) {
            this.state.loading = true
            server.getGroupInfo(groupId, groupInfo => {
                context.commit('setBusStopInfo', groupInfo)
            })
        },

        getBusStopRunsInfo(context, busStopId) {
            this.state.loading2 = true
            server.getRuns(busStopId, true, busStopRunInfo => {
                this.state.loading2 = false
                context.commit('setBusStopRunsInfo', busStopRunInfo)
            }, errorHandler =>{
                context.commit('setBusStopRunsError', errorHandler)
            })
        },
        
        getBusStopGroupRunsInfo(context, groupId) {
            this.state.loading2 = true
            server.getGroupRuns(groupId, true, busStopRunInfo => {
                this.state.loading2 = false
                context.commit('setBusStopRunsInfo', busStopRunInfo)
            }, errorHandler => {
                context.commit('setBusStopRunsError', errorHandler)
            })
        },

        getLineTrack(context, params) {
            this.state.loadingTrack = true
            server.getLineTrack(params.line, params.direction, params.race, params.spare2, lineCoords => {
                context.commit('setLineGeoTrack', lineCoords)
            })
        },

    },
    modules: {
    }
})
