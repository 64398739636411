import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import RouteMap from '../views/RouteMap.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        components:{
            default: Home,
            home: Home,
            routemap: RouteMap    
        } 
    },
    /*
    {
        path: '/routemap',
        name: 'routemap',
        component: RouteMap
    }
    */
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
