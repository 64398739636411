<template>
    <div class="home">
        <v-btn dark class="back" fixed absolute fab top left color="tplprimary" :style="btntop" @click="back()" v-if="pole.IsMixed && typeChoose != null">
           <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-container v-if="pole.IsMixed && typeChoose == null">
            <v-row>
                <v-col v-for="n in optionTypeCount()" :key="n" cols="12" :md="12 / optionTypeCount()" >
                    <v-card elevation="5" class="pa-2" flat :color="optionTypes[n-1].class">
                        <v-card-title primary-title class="justify-center" @click="chooseOption(optionTypes[n-1].choose)">
                            <span class="white--text display-1 font-weight-regular"> {{optionTypes[n-1].title.toUpperCase()}}</span>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-tabs v-if="(pole.IsMixed && typeChoose != null) || !pole.IsMixed" v-model="tab" background-color="tplsecondary" active-class="tplprimary" centered dark grow>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-departures">
                Partenze
            </v-tab>

            <v-tab href="#tab-arrivals">
                Arrivi
            </v-tab>
        </v-tabs>
        
        <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-departures'">
                <table style="width:100%;" v-if="showTable()">
                    <thead>
                        <tr :class="`head ${getTableClass()} nrow`">
                            <th class="cell cell-1">Linea</th>
                            <th class="cell cell-2">
                                <span>Partenza</span>
                            </th>
                            <th class="cell cell-3">
                                <span>Destinazione</span>
                            </th>
                            <th class="cell cell-4" v-if="showPlatform(departureRuns)">
                                <span>Corsia</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="!loading">
                        <tr v-for="(run, index) in departureRuns" v-bind:key="index">
                            <td :class="`cell cell-1 font-weight-black white--text ${getTableClass()} display-1`">{{run.LineCode}}</td>
                            <td class="cell cell-2 px-1">
                                <div class="title font-weight-black">{{run.DepartureTime | formatTime}}</div>
                                <div v-if="run.Vehicle != ''">
                                    <div>
                                        <img  src="static/bus-marker-alt.png" width=20 height=20 style="float:middle"/>
                                        <label style="float:middle; vertical-align: top; padding-left: 5px;" class="subtitle-2">{{run.Vehicle}}</label>
                                    </div>
                                </div>
                            </td>
                            <td class="cell cell-3 px-1" v-if="run.Note == ''">
                                <div class="d-block title font-weight-black text-uppercase">{{run.Destination}}</div>
                                <div class="d-block subtitle-2" v-if="run.NextPasses != ''">Prossime partenze: {{run.NextPasses}}</div>
                            </td>
                            <td class="cell cell-3 px-1" v-if="run.Note != ''">
                                <div class="d-block title font-weight-black title-w-note text-uppercase">{{run.Destination}}</div>
                                <div class="d-block note font-weight-note text-uppercase">{{run.Note}}</div>
                                <div class="d-block subtitle-2" v-if="run.NextPasses != ''">Prossime partenze: {{run.NextPasses}}</div>
                            </td>
                            <td class="cell cell-4 " v-if="showPlatform(departureRuns)">
                                <div class="title font-weight-black">{{run.Platform}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-tab-item>

            <v-tab-item :value="'tab-arrivals'">
                <table style="width:100%;" v-if="showTable()">
                    <thead>
                        <tr :class="`head ${getTableClass()} nrow`">
                            <th class="cell cell-1">Linea</th>
                            <th class="cell cell-2">
                                <div>Tempo di attesa</div>
                            </th>
                            <th class="cell cell-3">Provenienza</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loading">
                        <tr v-for="(run, index) in arrivalsRuns" v-bind:key="index" @click="openMap(run)">
                            <td :class="`cell cell-1 font-weight-black white--text ${getTableClass()} display-1`">{{run.LineCode}}</td>
                            <td class="cell cell-2 px-1">
                                <div class="font-weight-black"><span style=" white-space: nowrap;">{{run.ArrivalTime}}</span></div>
                                <div v-if="run.Vehicle != ''">
                                    <div>
                                        <img  src="static/bus-marker-alt.png" width=20 height=20 style="float:middle"/>
                                        <label style="float:middle; vertical-align: top; padding-left: 5px;" class="subtitle-2">{{run.Vehicle}}</label>
                                    </div>
                                </div>
                            </td>
                            <td class="cell cell-3 px-1" v-if="run.Note == ''">
                                <div class="d-block title font-weight-black text-uppercase">{{run.Departure}}</div>
                                <div class="d-block subtitle-2" v-if="run.NextPasses != ''">Prossimi arrivi: {{run.NextPasses}}</div>
                            </td>
                            <td class="cell cell-3 px-1" v-if="run.Note != ''">
                                <div class="d-block title font-weight-black title-w-note text-uppercase">{{run.Departure}}</div>
                                <div class="d-block note font-weight-note text-uppercase">{{run.Note}}</div>
                                <div class="d-block subtitle-2" v-if="run.NextPasses != ''">Prossime arrivi: {{run.NextPasses}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-tab-item>
        </v-tabs-items>

        <div style="width:100%; height:400px" v-if="showSpinner()">
            <v-card height="100%" class="d-flex justify-center mb-6 align-center"  color="grey lighten-5" flat   tile>
                <v-progress-circular
                    :size="70"
                    :width="7"
                    color="tplprimary"
                    indeterminate
                ></v-progress-circular>
            </v-card>
        </div>

         <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            >
            {{ snacktext }}
        </v-snackbar>
    </div>
</template>

<script>
    import moment from "moment"
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'home',
        data(){
            return {
                optionTypes:[],
                btntop: "top:" +(window.innerHeight - 80)+ "px",
                snackbar: false,
                timeout: 3000,
                snacktext: "",
                tab: null,
                TransitType: {
                    DepartureFromStop: "DepartureFromStop", 
                    TransitsFromStop: "TransitsFromStop", 
                    ArrivalAtStop: "ArrivalAtStop",
                }
            }
        },
        methods: {
           openMap(run){
                //run.Line, run.Race, run.Direction, run.Spare2, run.LineCode, run.IsStarted
                if(!run.IsStarted && run.Latitude == 0){
                    this.snacktext = "Linea: "+ run.LineCode +" - Informazioni non disponibili."
                    this.snackbar = true
                    return
                }
                var lineObj = {}
                lineObj.Number = run.Line
                lineObj.Code = run.LineCode
                lineObj.Race = run.Race 
                lineObj.PoleCoords = this.getPoleCoors(run)
                this.$store.commit("setLine", lineObj)  
                var params = {}
                params.line = run.Line
                params.direction = run.Direction
                params.race = run.Race 
                params.spare2 = run.Spare2
                
                this.$store.dispatch("getLineTrack", params)   
               
                // Vado alla mappa alla fine del caricamento della traccia
                // vedi watch
                // this.$router.push({name: "routemap"})

           }, 
           getPoleCoors(run){
               if(this.pole.Stops == undefined){
                   return [this.pole.Longitude, this.pole.Latitude]
               } else {
                   var poleOfGroup =  this.pole.Stops.filter(function(x) {return x.StopCode == run.StopCode})[0]
                   return [poleOfGroup.Longitude, poleOfGroup.Latitude]
               }    
           },
           getTableClass(){
                if(!this.pole) return ""
                if(this.pole.IsMixed){
                    if(this.typeChoose == "urban") return "tplurban"
                    if(this.typeChoose == "extraurban") return "tplextraurban"
                    if(this.typeChoose == "maritime") return "tplmaritime"
                } else {
                    if(this.pole.Stops == undefined) {
                        if(this.pole.IsUrban) return "tplurban"
                        if(this.pole.IsExtraUrban) return "tplextraurban"
                        if(this.pole.IsMaritime) return "tplmaritime"
                    } else {
                        if(this.pole.Stops[0].IsUrban) return "tplurban"
                        if(this.pole.Stops[0].IsExtraUrban) return "tplextraurban"
                        if(this.pole.Stops[0].IsMaritime) return "tplmaritime"
                    }
                }
            },
            optionTypeCount(){
                var result = 0
                if(this.pole.IsUrban) result++
                if(this.pole.IsExtraUrban) result++
                if(this.pole.IsMaritime) result++
                return result
            },
            chooseOption(option){
                this.$store.commit("setTypeChoose", option)
            }, 
            showSpinner(){
                if(this.loading){
                    if(!this.pole.IsMixed){
                        return true
                    } else {
                        if(this.typeChoose != null) return true
                    }
                    return false
                }
            },
            showTable(){
                if(!this.loading){
                    if(this.pole.IsMixed){
                        if(this.typeChoose != null) return true
                    } else {
                        return true
                    }
                }
                return false
            }, 
            showPlatform(runs){
                for(var i=0; i< runs.length; i++){
                    if(runs[i].Platform!="") return true
                }
                return false
            }, 
            back(){
                this.$store.commit("setTypeChoose", null)
            },
            onResize() {
                this.btntop = "top:" +(window.innerHeight - 80)+ "px"
            },
        },
        computed:{ 
            ...mapState({
                pole: state => state.busStopInfo,
                loading: state => state.loading,
                runs: state => state.runs,
                loadingTrack: state => state.loadingTrack,
                typeChoose: state => state.typeChoose,
                activeTab: state => state.stationTab 
            }),
            filteredRuns: function(){
                var result
                if(this.typeChoose == null){
                    result = this.runs
                } else {
                    if(this.typeChoose == "urban"){
                        result = this.runs.filter(function(item){
                            return item.LineType == "1U"
                        })
                    }
                    if(this.typeChoose == "extraurban"){
                        result = this.runs.filter(function(item){
                            return item.LineType == "1E"
                        })
                    }
                }
                
                return result
            },
            departureRuns: function(){
                var self = this;
                var result = this.filteredRuns.filter(function(item){
                    if (item.TransitType == self.TransitType.DepartureFromStop || item.TransitType == self.TransitType.TransitsFromStop) {
                        return true
                    }
                })
                return result
            },
            arrivalsRuns: function(){
                var self = this;
                var result = this.filteredRuns.filter(function(item){
                    if (item.TransitType == self.TransitType.ArrivalAtStop || item.TransitType == self.TransitType.TransitsFromStop) {
                        return true
                    }
                })
                return result
            },
        },
        mounted(){
            this.$store.commit("setLine", null)  
            this.$store.commit("setLineGeoTrack", null)
            this.optionTypes.push({
                title: "Urbano",
                class: "tplurban",
                choose: "urban"
            })
            this.optionTypes.push({
                title: "Extraurbano",
                class: "tplextraurban",
                choose: "extraurban"
            })
            this.optionTypes.push({
                title: "Marittimo",
                class: "tplmaritime",
                choose: "maritime"
            })
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
                window.addEventListener('orientationchange', this.onResize);
            })
        }, 
        watch:{
            loadingTrack: function(val){
                // aspetto che finisca di caricare il tracciato prima di caricare la mappa
                // workaround: la mappa non percepisce i cambiamenti delle variabili nello store
                if(!val) this.$store.commit("setCurrentComponent", "routemap")
            },
        },
        updated: function () {
            this.$nextTick(function () {
                if (this.activeTab == "tab-arrivals"){ // Uso activeTab come semaforo (sto arrivando dalla mappa)
                    this.tab = "tab-arrivals"
                    this.$store.commit("setStationTab", null)
                }
            })
        }
    }
</script>
<style scoped>

    th.cell{
        padding-left: 3px;
        padding-right: 3px;
    } 
    th.cell-1, th.cell-3{
        font-size:1.4em;
    }   
    tr {
        margin: 0;
        height: 55px; 
    }

    td {
        margin: 0
    }

        td:nth-child(n+2) {
            border-bottom: 1px solid var(--v-tplsecondary-lighten5);
            border-right: 1px solid var(--v-tplsecondary-lighten5);
        }

    .home {
        width: 100%;
    }

    .logo {
        fill: var(--v-tplprimary-base);
    }

    .time {
        font-size: 1.5em;
        font-weight: 800;
        height: 100%;
        margin-top: 0.8em;
    }

    .head {
        background-color: var(--v-tplprimary-base);
        color: white;
        text-transform: uppercase;
        font-weight: 600;
    }

        .head .subrow {
            background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
            letter-spacing: 0.3em;
        }

        .head .subrow-under {
            font-size: 0.9em;
            padding-top: 5px;
            font-weight: lighter;
        }

        .head .cell3 {
            border-right: none;
        }

    .nrow {
        height: 60px;
        vertical-align: middle;
    }

    .subrow {
        padding-left: 3px;
        height: 28px;
    }

    .cell {
        border: none;
        border-top: none;
        border-right: none;
    }

    .cell-1 {
        text-align: center;
        width: 8%;
    }

    .cell-2 {
        border-left: none;
        text-align: center;
        width: 15%;
        /* min-width: 100px; */
    }

    .cell-3 {
        width: 100%;
    }
    .cell-4 {
        text-align: center;
        width: 4%;
    }

    .tplurban{
        background-color: var(--v-tplurban)
    }
    .tplextraurban{
        background-color: var(--v-tplextraurban)
    }
    .tplmaritime{
        background-color: var(--v-tplmaritime)
    }
    .back{
        margin: 0 0 43px 0;
    }
    .font-weight-black{
        font-size: 1.4em;
    }
    .font-weight-note{
        font-size: 0.9em;
        font-weight: bold;
        padding-top: 2px;
    }
    .title-w-note{
        height: none; 
    }
    
    .v-tab{
        font-size: 1.2em;
    }

    @media only screen and (max-width: 800px) {
        th.cell-2,th.cell-4 span{
            font-size: 2vw;
        }    
    }

</style>