<template>
    <v-app>
        <v-app-bar :height="appbarHeight" flat app>
            <v-container fluid class="headerContainer">
                <v-layout row>
                    <img src="static/logo-ext.svg" class="logo" />

                    <svg aria-hidden="true" focusable="false" style="width:0;height:0;position:absolute;">
                        <linearGradient id="gradient-horizontal">
                            <stop offset="0%" stop-color="var(--color-stop-1)" />
                            <stop offset="52%" stop-color="var(--color-stop-1)" />
                            <stop offset="52%" stop-color="var(--color-stop-2)" />
                            <stop offset="100%" stop-color="var(--color-stop-2)" />
                        </linearGradient>
                    </svg>

                    <v-toolbar-title class="toolbartitle">
                        <h3 class="address">{{ getDate }}</h3>
                        <span class="stopcode">fermata <strong v-if="pole.StopCode != null">{{ pole.StopCode }}</strong>
                            <strong v-if="pole.Name != null">{{ pole.Name }}</strong> <span v-if="line != null">linea
                                <strong>{{ line.Code }}</strong></span></span>
                    </v-toolbar-title>


                    <v-spacer></v-spacer>

                    <div class="time">{{ now }}</div>

                </v-layout>
                <v-layout row>
                    <div class="subHeader">
                        <h3 class="address">{{ pole.Address }}</h3>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="countdown">
                        <span v-show="!loading2">Prossimo aggionamento tra {{ nextUpdate > 1 ? nextUpdate + ' secondi' : 'un secondo' }}</span>
                        <span v-show="loading2">Aggiornamento in corso...</span>
                    </div>
                </v-layout>
            </v-container>
        </v-app-bar>

        <v-main>
            <div class="page-wrapper">
                <div :is="currentComponent"></div>
            </div>
            <div style="height:60px"></div>
        </v-main>

        <v-dialog v-model="showDialog" max-width="500">
            <v-img :src="imageUrl" contain></v-img>
            <div style="background-color: white; padding-left: 10px; padding-right: 10px;">
                <h3 style="margin-bottom: 10px;padding-top: 5px;">Attenzione!</h3>
                <p style="font-size: 15px;line-height: 15pt; margin-bottom: 0pt;">{{popupMessage[0]}} 
                    <b>{{popupMessage[1]}}</b>, a <b>{{ popupMessage[2] }}</b> e per alcune ore aggiorneremo i nostri sistemi informatici.
                    <br>
                    Durante l'aggiornamento il servizio di <b>tempo reale non sarà disponibile.</b>
                    <br>
                    Sarà sempre possibile consultare il travel planner, le tabelle orarie sul sito e gli orari esposti in fermata.
                    <br><br>
                    Grazie per la collaborazione
                    <br><br>
                </p>
            </div>
            <v-btn color="primary" @click="showDialog = false">Chiudi</v-btn>
        </v-dialog>

        <tplfooter v-show="$route.query.totem == null" />
    </v-app>
</template>

<script>
import moment from "moment"
import axios from "axios"
import { mapState, mapActions } from 'vuex'
import Home from "./views/Home"
import Group from "./views/Group"
import Station from "./views/Station"
import RouteMap from "./views/RouteMap"
import TplFooter from "./components/TplFooter"

export default {
    name: 'App',
    props: {
        wichTab: String,
    },
    components: {
        tplfooter: TplFooter,
        'home': Home,
        'station': Station,
        'group': Group,
        'routemap': RouteMap,
    },
    data() {
        return {
            now: moment().format("HH:mm"),
            nowDate: moment().locale("it").format('DD MMMM'),
            refreshTime: 30,
            next: 0,
            windowWidth: 0,
            appbarHeight: 130,
            showDepartures: true,
            showDialog: false,
            imageUrl: 'static/TitoloPopup.png',
            popupMessage: [],
        }
    },
    created() {
        axios.get("static/appsettings.json").then(response => {
            localStorage.setItem("ApiUrl", response.data.ApiUrl)
            localStorage.setItem("MecurioApiUrl", response.data.MecurioApiUrl)
        });
        this.$store.commit("setStopCode", this.$route.query.stopcode)
        this.$store.commit("setGroupCode", this.$route.query.groupcode)
        if (this.$route.query.stopcode != null) {
            this.$store.dispatch('getBusStopInfo', this.$route.query.stopcode)
            this.$store.dispatch('getBusStopRunsInfo', this.$route.query.stopcode)
        } else {
            if (this.$route.query.groupcode != null) {
                this.$store.dispatch('getGroupInfo', this.$route.query.groupcode)
                this.$store.dispatch('getBusStopGroupRunsInfo', this.$route.query.groupcode)
            }
        }
    },
    mounted() {
        this.$options.interval = setInterval(this.updateDateTime, 1000);
        this.$options.interval = setInterval(this.updateRuns, this.refreshTime * 1000);
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        });
        this.setHeight(window.innerWidth)
        this.displayPopup();
    },
    methods: {
        updateDateTime() {
            this.next += 1
            this.now = moment().format("HH:mm")
        },
        updateRuns() {
            this.next = 0
            if (this.$route.query.stopcode != null) {
                this.$store.dispatch('getBusStopRunsInfo', this.pole.StopCode)
            } else {
                if (this.$route.query.groupcode != null) {
                    this.$store.dispatch('getBusStopGroupRunsInfo', this.$route.query.groupcode)
                }
            }
            if (this.errorHandeler) console.error(this.errorHandeler)
        },
        setHeight(val) {
            if (val < 780) {
                this.appbarHeight = val / 5.8;
            } else {
                this.appbarHeight = 130
            }
        },
        displayPopup() {
            this.showDialog = false;
            if (moment().isBetween('2023-03-17', '2023-03-22')) {
                this.popupMessage[0] = 'Nelle serate del';
                this.popupMessage[1] = '20 e 21 marzo';
                this.popupMessage[2] = 'partire dalle ore 19.00';
                this.showDialog = true;
            }
            if (moment().isBetween('2023-03-24', '2023-03-29')) {
                this.popupMessage[0] = 'Nella serata del';
                this.popupMessage[1] = '28 marzo';
                this.popupMessage[2] = 'partire dalle ore 19.00';
                this.showDialog = true;
            }
        }
    },
    computed: {
        ...mapState({
            pole: state => state.busStopInfo,
            loading: state => state.loading,
            loading2: state => state.loading2,
            line: state => state.line,
            currentComponent: state => state.currentComponent,
            busStopInfo: state => state.busStopInfo,
            errorHandeler: state => state.errorHandeler
        }),
        nextUpdate() {
            return this.refreshTime - this.next
        },
        getDate() {
            return moment().format('D MMMM')
        }
    },
    watch: {
        pole: function (val) {

            if (val.Stops == undefined) {
                if ((val.IsUrban && val.IsExtraUrban) || (val.IsExtraUrban && val.IsMaritime) || (val.IsUrban && val.IsMaritime)) {
                    val.IsMixed = true
                } else {
                    val.IsMixed = false
                }
            } else {
                val.IsMixed = false
                val.Stops.forEach(currentStop => {
                    if ((currentStop.IsUrban && currentStop.IsExtraUrban) || (currentStop.IsExtraUrban && currentStop.IsMaritime) || (currentStop.IsUrban && currentStop.IsMaritime)) {
                        val.IsMixed = true
                }});
            }
        },
        busStopInfo: function (stop) {
            if (stop.Stops == undefined) {
                if (stop.IsStation) {
                    //console.log("qua")
                    //this.$store.commit("setStationTab", "tab-departures")
                    this.$store.commit("setCurrentComponent", "station")
                } else {
                    this.$store.commit("setCurrentComponent", "home")
                }
            } else {
                var stationFound = false
                stop.Stops.forEach(currentStop => {
                    if (currentStop.IsStation)
                        stationFound = true;
                });
                if (stationFound == true)
                    this.$store.commit("setCurrentComponent", "station")
                else this.$store.commit("setCurrentComponent", "group")
            }
        },
        windowWidth(newWidth, oldWidth) {
            this.setHeight(newWidth)

        },
        showDepartures: function (newVal, oldVal) {
            this.$store.commit("setDeparturesArrivals", newVal)
        }

    }
};
</script>

<style scoped>
#gradient-horizontal {
    --color-stop-1: var(--v-tplprimary-base);
    --color-stop-2: var(--v-tplsecondary-base);
}

.logo {
    height: 100%;
    fill: url(#gradient-horizontal) var(--v-tplprimary-base);
    margin-right: 20px;
}

.address {
    font-size: 24px;
    word-wrap: break-word;
    white-space: nowrap;
}

.time {
    font-size: 24px;
    font-weight: 800;
    clear: right;
    display: block;
    padding-top: 18px;
}

.countdown {
    right: 15px;
    top: 80px;
    display: block;
    clear: right;
    font-size: 12px;
    display: none;
}

.stopcode {
    font-size: 15px;
    letter-spacing: 0.1666666667em !important;
    text-transform: uppercase;
}

.headerContainer {
    padding-top: 20px;
}


@media (min-width: 1000px) {

    .countdown {
        display: block;
    }

}

@media (max-width: 780px) {

    .stopcode,
    .address,
    .time {
        font-size: 3vw;

    }
}

@media (max-width: 1000px) {
    .logo {
        width: 17vw;
        max-width: 132px;
    }

    .toolbartitle {
        line-height: 1.1;
    }

}



.theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #fff;
}
</style>
